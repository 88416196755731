import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import Breadscrum from "../components/Breadscrum";
import { Router } from "@reach/router";
import NabidkaRakvi from "../components/NabidkaRakvi";


import GooglePic from "../assets/img/googleFullRating.svg";
import FirmyPic from "../assets/img/firmyFullRating.svg";



const Rating = styled.div`
  margin-top: 15px;
  display: flex;
  width: 500px;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 540px) {
    display: block;
  }

  img {
    display: block;
    max-height: 28px;
    max-width: 200px;
    margin-right: 30px;
    width: fit-content;

    @media (max-width: 899px) { 
      width: fit-content;
      margin-right: 20px;
    }

    @media (max-width: 540px) {
      margin-top: 10px;

      max-height: none;
      max-width: none;
    }
  }
`;

const RakveInner = styled.section`
  background: #FBFBFA;

  .rakveWrap {
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 0 auto;
    padding-top: 60px;

    @media(max-width: 899px) {
      width: calc(100% - 50px);
      padding-top: 30px;
    }
  }

  h1 {
    display: block;
    font-size: 40px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-family: NewKansas;
    color: #243A58;

    @media(max-width: 899px) {
      font-size: 32px;
    }
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #2F2F2F;
    line-height: 25px;

    a {
      color: #2F2F2F;
    }
  }

  .perex {
    font-family: Visuelt-Regular;
    font-size: 18px;
    color: #000000;
    line-height: 27px;

    @media(max-width: 899px) {
      line-height: 25px;
      font-size: 16px;
    }
  }

  h2 {
    display: block;
    font-family: Visuelt-Medium;
    font-size: 25px;
    color: #243A58;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .kategorie {
    margin-bottom: 20px;

    a {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #000000;
      text-align: center;
      padding: 17px 0;
      width: 200px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
      border-radius: 5px;
      text-decoration: none;
      display: inline-block;
      margin-right: 30px;
      margin-bottom: 30px;

      @media(max-width: 899px) {
        margin-right: 15px;
        width: 120px;
        margin-bottom: 15px;
      }
    }

    .aktivni {
      background: #3A557C;
      color: #FFFFFF;
      border: 1px solid #3A557C;
    }
  }
`;


const Hlava = ({h1, path, title, desc}) => {

  let stranky = [
    {name: "Domů", url: "/"},
    {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
    {name: "Rakve", url: "/rakve/"},
  ]

  if (path !== "/") {
    stranky.push({name: h1, url:`/rakve${path}`})
  }


  return(<>
    <SEO
      title={title}
      customTitle
      description={desc}
      image={"/pohrebnisluzbaOg.png"}
    />

    <Breadscrum
      visible={true}
      stranky={stranky}
    />

    <h1>{h1}</h1>
  </>);
}

const RakvePage = ({data}) => {

  const isPlus900 = useMediaQuery({
    query: '(min-width: 899px)'
  });

  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });

  let structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Rakve",
    "brand":"Pohřební služba - Goodbye.cz",
    "image":"https://goodbye.cz/rakev.jpg",
    "description": "Naše pohřební služba nabízí široké spektrum kvalitních pohřebních rakví od českých a slovenských výrobců. V nabídce najdete rakve bezobřadní, základní, ale také zdobené a luxusní. Pokud byste potřebovali poradit s výběrem rakve na pohřeb, neváhejte se na nás obrátit.",
    "aggregateRating": {
      "@type":"AggregateRating",
      "bestRating": "5",
      "ratingCount": 48,
      "ratingValue": 4.9,
      "worstRating": "1"
    }
  };






  return (
    <Layout V2>

      

      

      <RakveInner>
        <div className="rakveWrap">

          <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}} />

          <Router basepath="/rakve/">
            <Hlava path="/"
              h1={"Rakve"}
              title="Rakve na pohřeb (katalog, ceny) | Pohřební služba Goodbye.cz"
              desc="Naše pohřební služba nabízí pohřebních rakve od českých a slovenských výrobců. V nabídce najdete rakve bezobřadní, základní, ale také zdobené a luxusní."
            />
            <Hlava
              path="/obradni/"
              h1={"Obřadní rakve"}
              title="Obřadní rakve (katalog, ceny) | Pohřební služba Goodbye.cz"
              desc="Obřadní rakve od českých a slovenských výrobců - katalog pohřební služby. Cena rakve se odvíjí od použitého materiálu, typu zdobení a velikosti."
            />
            <Hlava path="/drevene/"
              h1={"Dřevěné rakve"}
              title="Dřevěné rakve (katalog, ceny) | Pohřební služba Goodbye.cz"
              desc="Dřevěné rakve od českých a slovenských výrobců - katalog pohřební služby. Cena rakve se odvíjí od použitého materiálu, typu zdobení a velikosti."
            />
            <Hlava path="/zdobene/"
              h1={"Zdobené rakve"}
              title="Zdobené rakve (katalog, ceny) | Pohřební služba Goodbye.cz"
              desc="Zdobené rakve od českých a slovenských výrobců - katalog pohřební služby. Cena rakve se odvíjí od použitého materiálu, typu zdobení a velikosti."
            />
            <Hlava path="/vyrezavane/"
              h1={"Vyřezávané rakve"}
              title="Vyřezávané rakve (katalog, ceny) | Pohřební služba Goodbye.cz"
              desc="Vyřezávané rakve od českých a slovenských výrobců - katalog pohřební služby. Cena rakve se odvíjí od použitého materiálu, typu zdobení a velikosti."
            />
            <Hlava path="/luxusni/"
              h1={"Luxusní rakve"}
              title="Luxusní rakve (katalog, ceny) | Pohřební služba Goodbye.cz"
              desc="Obřadní rakve od českých a slovenských výrobců - katalog pohřební služby. Cena rakve se odvíjí od použitého materiálu, typu zdobení a velikosti."
            />
            <Hlava path="/zakladni/"
              h1={"Základní rakve"}
              title="Základní rakve (katalog, ceny) | Pohřební služba Goodbye.cz"
              desc="Základní rakve od českých a slovenských výrobců - katalog pohřební služby. Cena rakve se odvíjí od použitého materiálu, typu zdobení a velikosti."
            />
          </Router>

          <p className="perex">Naše <Link to="/pohrebni-sluzba/">pohřební služba</Link> nabízí široké spektrum kvalitních pohřebních rakví od českých a slovenských výrobců. V nabídce najdete rakve bezobřadní, základní, ale také zdobené a luxusní. Pokud byste potřebovali poradit s výběrem rakve na pohřeb, neváhejte se na nás obrátit.</p>
          
          <Rating>
            <img alt="Nejlépe hodnocená pohřební služba - Google" src={GooglePic} />
            <img alt="Nejlépe hodnocená pohřební služba - Firmy.cz" src={FirmyPic} />
          </Rating>

          <h2>Ceny</h2>
          <p><Link to="/poradna/prehled-cen-pohrbu/">Cena rakve</Link> se odvíjí od použitého materiálu, typu zdobení a také velikosti. Mezi levnější patří například rakve z borovicového dřeva, naopak dražší jsou rakve dubové. Mezi luxusnější a dražší pohřební rakve patří také rakve amerického typu nebo rakve kovové.</p>
          
          <h2>Typy rakví</h2>
          <p>Pohřební rakve se mohou řadit do mnoha kategorií, především podle typu dřeva a stylu zdobení, ale také například podle barvy, čalounění, vložky, typu madel nebo možnosti otevírání víka. Nejčastěji jsou v Česku kupované rakve celodřevěné, vyráběné z topolového, borového, smrkového nebo dubového dřeva. Zdobení může být buď vypalováno nebo vyřezáváno přímo na rakev nebo to může být také folie se vzorem či ozdobné kování a šrouby.</p>

          <h2>Naše nabídka rakví</h2>
          <div className="kategorie">
            <Link activeClassName="aktivni" to="/rakve/">Všechny</Link>
            <Link activeClassName="aktivni" to="/rakve/obradni/">Obřadní</Link>
            <Link activeClassName="aktivni" to="/rakve/drevene/">Dřevěné</Link>
            <Link activeClassName="aktivni" to="/rakve/zdobene/">Zdobené</Link>
            <Link activeClassName="aktivni" to="/rakve/vyrezavane/">Vyřezávané</Link>
            <Link activeClassName="aktivni" to="/rakve/luxusni/">Luxusní</Link>
            <Link activeClassName="aktivni" to="/rakve/zakladni/">Základní</Link>
          </div>
          
        </div> 

        <div className="nabidkaRakvi">
          <Router basepath="/rakve/">
            <NabidkaRakvi path="/" rakve={data.allStrapiProducts.edges} type={"vsechny"} />
            <NabidkaRakvi path="/obradni/" rakve={data.allStrapiProducts.edges} type={"obradni"} />
            <NabidkaRakvi path="/drevene/" rakve={data.allStrapiProducts.edges} type={"drevene"} />
            <NabidkaRakvi path="/zdobene/" rakve={data.allStrapiProducts.edges} type={"zdobene"} />
            <NabidkaRakvi path="/vyrezavane/" rakve={data.allStrapiProducts.edges} type={"vyrezavane"} />
            <NabidkaRakvi path="/luxusni/" rakve={data.allStrapiProducts.edges} type={"luxusni"} />
            <NabidkaRakvi path="/zakladni/" rakve={data.allStrapiProducts.edges} type={"zakladni"} />
          </Router>
        </div>

      </RakveInner>

    </Layout>
  );
}

export const query = graphql`
  query {
    allStrapiProducts(filter: {type: {eq: "rakev"}}) {
      edges {
        node {
          id
          name
          price
          regions {
            id
            name
          }
          rakveKategorie
          description
          favourite
          catalogueName
          picture {
            url
          }
        }
      }
    }
  }
`;




export default RakvePage;
